import Vue from 'vue';
import router from './router/index'
import ElementUI from 'element-ui';
import './assets/css/reset.css'
import './assets/css/common.css'
import 'element-ui/lib/theme-chalk/index.css'; 
import App from './App.vue';
import Cookies from 'js-cookie'
import 'element-ui/lib/theme-chalk/display.css';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$cookies=Cookies;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');



// import { getKey } from '@/utils/personkey'
// import Vue from 'vue';
// import axios from 'axios';
// import router from './router';
// import ElementUI from 'element-ui';
// import './assets/css/reset.css'
// import './assets/css/common.css'
// import 'element-ui/lib/theme-chalk/index.css'; 
// import App from './App.vue';
// import Cookies from 'js-cookie'
// import 'element-ui/lib/theme-chalk/display.css';

// Vue.use(axios);

// Vue.use(ElementUI);
// Vue.config.productionTip = false
// Vue.prototype.$cookies=Cookies;

// axios.interceptors.request.use(config => {
//   // 在请求发送之前，检查是否存在token，并设置到请求头中
//   const token = getKey()
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });


// // 设置全局的响应拦截器
// axios.interceptors.response.use(response => {
//   return response;
// }, error => {
//   // 如果返回状态码为401，则跳转到登录接口
//   if (error.response && error.response.status === 401) {
//     router.push('/login');
//   }
//   return Promise.reject(error);
// });

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app');