import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '@/views/AboutView'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name:'HomeView',
    component: () => import('@/views/home/HomeIndex'),
    
    chirldren:[
      
        {
          path: 'HomeIndex',
          name:'HomeIndex',
          component: ()=>import('@/views/home/HomeIndex')
        },
        
    ]
  },
  {
    path: '/food',
    name: 'food',
    component: () => import(/* webpackChunkName: "about" */ '@/views/news/food.vue'),

  },
  {
    path: '/foodDetail',
    name: 'foodDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/news/foodDetail.vue'),

  },
  {
    path: '/tiyan',
    name: 'tiyan',
    component: () => import(/* webpackChunkName: "about" */ '@/views/news/tiyan.vue'),

  },
  
  {
    path: '/tiyanDetail',
    name: 'tiyanDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/news/tiyanDetail.vue'),

  },
  {
    path: '/Service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "about" */ '@/views/service/ServiceList.vue'),

  },
  {
    path: '/serviceDetail',
    name: 'serviceDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/service/serviceDetail.vue'),

  },
  {
    path: '/Join',
    name: 'Join',
    component: () => import(/* webpackChunkName: "about" */ '@/views/join/JoinMe.vue'),
  },
  {
    path: '/joinDetail',
    name: 'joinDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/join/joinDetail.vue'),
  },




]

const router = new VueRouter({
  mode: 'hash',//去掉#号
  base: "/h5/",
  // base:__dirname,
  routes
})

export default router
